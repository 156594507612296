
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonRow, IonCol, IonSearchbar, toastController, IonSpinner } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Stars',
  data () {
    return {
      stars: [],
      totalStars: 0,
      isLoading: false,
      showResultCount: true,
      searchInput: "",
      submittedSearch: false
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonRow,
    IonCol,
    IonSearchbar,
    IonSpinner
  },
  created() {

    axios.get(process.env.VUE_APP_API_URL +'/users')
        .then(response => {
          this.stars = response.data["hydra:member"];
          this.totalStars = response.data["hydra:totalItems"];
        })
  },
  methods: {
    async openToast(message: string, duration: number, color: string) {

      if (!color){
        color = "primary";
      }

      const toast = await toastController
          .create({
            message: message,
            duration: duration
          })
      return toast.present();
    },
    search() {

      if (this.removeWhitespaces(this.searchInput) == ""){
        this.openToast('Eingabe darf nicht leer sein.', 2000);
      } else {
        this.isLoading = true;
        this.submittedSearch = true;

        axios.get(process.env.VUE_APP_API_URL +'/users?username=' + this.removeWhitespaces(this.searchInput))
            .then(response => {
              this.stars = response.data["hydra:member"];
              this.totalStars = response.data["hydra:totalItems"];
            })
            .finally(() => {
                this.isLoading = false;
            });
      }

    },
    removeWhitespaces(str){
      return str.trim();
    },
    showSearch(){
      this.showResultCount = false;
    },
    getRoute(id: string) {
      return "/star/" + id;
    },
    hideSearch(){
      this.searchInput = "";
      this.showResultCount = true;
      this.submittedSearch = false;

      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL +'/users')
          .then(response => {
            this.stars = response.data["hydra:member"];
            this.totalStars = response.data["hydra:totalItems"];
          }).finally(() => {
        this.isLoading = false;
      });

    },
  }
});

