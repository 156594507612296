<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Stars</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            Stars
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card @click="showSearch">
        <ion-card-header>
          <ion-card-subtitle v-if="showResultCount">{{ totalStars }} Stars gefunden</ion-card-subtitle>
          <small v-if="showResultCount">Klicken zum suchen</small>
          <form v-on:submit.prevent="search" v-if="!showResultCount">
            <ion-searchbar show-cancel-button="always" enterkeyhint="search" :onIonCancel="hideSearch" v-model="searchInput" animated></ion-searchbar>
            <ion-card-subtitle v-if="!isLoading && totalStars > 0 && submittedSearch" style="text-align: center;">{{ totalStars }} Ergebnisse gefunden</ion-card-subtitle>
          </form>
        </ion-card-header>
      </ion-card>

      <div v-if="!isLoading">
        <ion-card v-for="(star, key) in stars" :key="key" :router-link="getRoute(star.id)">
          <ion-card-header>
            <ion-row>
              <ion-col size="3">
                <img class="rounded-image imageCircle" src="http://via.placeholder.com/50x50.png?text=Avatar" alt="test" />
              </ion-col>
              <ion-col size="1"></ion-col>
              <ion-col size="8">
                <ion-card-subtitle>{{ star.firstname }} {{ star.lastname }}</ion-card-subtitle>
                <ion-card-title>{{ star.username }}</ion-card-title>
                <ion-card-subtitle>{{ star.inserats.length }} Inserate</ion-card-subtitle>
              </ion-col>
            </ion-row>
          </ion-card-header>
        </ion-card>
      </div>

      <div class="container" v-if="isLoading">
        <ion-spinner name="dots"></ion-spinner>
      </div>

      <div class="container" v-if="!isLoading && stars.length === 0">
        <strong>Keine Ergebnisse</strong>
        <p>Versuch es mit einem anderen Begriff.</p>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonRow, IonCol, IonSearchbar, toastController, IonSpinner } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Stars',
  data () {
    return {
      stars: [],
      totalStars: 0,
      isLoading: false,
      showResultCount: true,
      searchInput: "",
      submittedSearch: false
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonRow,
    IonCol,
    IonSearchbar,
    IonSpinner
  },
  created() {

    axios.get(process.env.VUE_APP_API_URL +'/users')
        .then(response => {
          this.stars = response.data["hydra:member"];
          this.totalStars = response.data["hydra:totalItems"];
        })
  },
  methods: {
    async openToast(message: string, duration: number, color: string) {

      if (!color){
        color = "primary";
      }

      const toast = await toastController
          .create({
            message: message,
            duration: duration
          })
      return toast.present();
    },
    search() {

      if (this.removeWhitespaces(this.searchInput) == ""){
        this.openToast('Eingabe darf nicht leer sein.', 2000);
      } else {
        this.isLoading = true;
        this.submittedSearch = true;

        axios.get(process.env.VUE_APP_API_URL +'/users?username=' + this.removeWhitespaces(this.searchInput))
            .then(response => {
              this.stars = response.data["hydra:member"];
              this.totalStars = response.data["hydra:totalItems"];
            })
            .finally(() => {
                this.isLoading = false;
            });
      }

    },
    removeWhitespaces(str){
      return str.trim();
    },
    showSearch(){
      this.showResultCount = false;
    },
    getRoute(id: string) {
      return "/star/" + id;
    },
    hideSearch(){
      this.searchInput = "";
      this.showResultCount = true;
      this.submittedSearch = false;

      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL +'/users')
          .then(response => {
            this.stars = response.data["hydra:member"];
            this.totalStars = response.data["hydra:totalItems"];
          }).finally(() => {
        this.isLoading = false;
      });

    },
  }
});

</script>

<style scoped>

  .container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .container strong {
    font-size: 20px;
    line-height: 26px;
  }

  .container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }

  .container a {
    text-decoration: none;
  }

  .imageCircle {
    border: 2px solid #1dc0c6;
  }

  .rounded-image {
    border-radius: 50%;
  }

</style>